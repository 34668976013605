<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-6">
      <h2>{{ $t("navigations.survival analysis") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content" id="survivalanalysis">
    <div class="col-lg-12">
      <SurvivalChart />
              
    </div>
  </div>
</template>
  
  <script>
  import SurvivalChart from '../../custom_lib/AiCharts/SurvivalChart.vue';
  export default {
    name: 'survivalanalysis',
    components: {
      SurvivalChart
    },
    data() {
      return {
      };
    }
  };
  </script>
  