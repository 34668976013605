<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.demographics summary ") }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-2" @click="pageclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-4 pr-0 mb-1">
        <div class="stat-card">
          <div class="stat-header">
            <span>Total Subjects</span>
          </div>
          <div class="stat-body">
            <div class="stat-info">
              <h1>{{ totalCount }}</h1>
              <small>Subjects</small>
            </div>
            <div class="stat-icon">
              <img src="../../assets/img/stat_card_icon.png" alt="Icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 mb-1">
        <Percentagechart :data="data" :fromPage="'demographicsScreen'"/>
      </div>
      <div class="col-md-12 mb-2">
        <historytab :historyarray="historykeys" @gotohistorylevel="gotohistorylevel" />
      </div>
      <div class="col-md-6 mb-2 pr-1">
        <stackedbarchart :availableColumns="arrayofkeys" xAxisKey="SEX" yAxisKey="AGE" segmentKey="RACE"
          :loader="loader" :data="data" :templateId="''" :fromPage="'descriptiveStatistics'"
          chartlabel="Race Distribution by Demographic Segments" @drilldowncurrentchart="drilldowncurrentchartforbar"
          @opendrillpopup="drillpopupopened" />
      </div>
      <div class="col-md-6 mb-2">
        <D3PieChart :data="data" :templateId="''"  :loader="loader" :fromPage="'descriptiveStatistics'"
        :xaxisvariables="arrayofkeys"
        :tableData="data"
            :xaxiskey="xaxiskeys['piechart']"
          @opendrillpopup="drillpopupopened" @drilldowncurrentchart="drilldowncurrentchartforbar"
           @setxaxiskey="retainxaxiskey"
          @undobutton="undobutton" />
      </div>
      <div class="col-md-6 mb-2 pr-1">
            <histogram
                xAxisKey="EXERFREQ"
                yAxisKey="subjects"
                :loader="loader"
                :data="data"
                 :tableData="data"
                :templateId="templateId"
                :fromPage="'descriptiveStatistics'"
                chartlabel="Histogram of Treatment Duration"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
            />
          </div>
          <div class="col-md-6 mb-2">
            <groupbarchart
                :availableColumns=arrayofkeys
                :loader="loader"
                :data="data"
                templateId=""
                :xAxisValue="groupchartkeys['xaxiskey']"
                yAxisValue=""
                :groupCategoryValue="groupchartkeys['groupkey']"
                :fromPage="'demographicsScreen'"
                chartlabel="Test Status Summary"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
                @setgroupkeys="retaingroupchartvars"
            />
          </div>
      <div class="col-lg-12 mb-1">
        <div class="stat-card-white ">
          <div class="stat-header-white">
            <span>Patient Distribution</span>
          </div>
          <div class="stat-body-white">
            <advtable :loader="loader" :data="data" fromPage="patientRetention"
              @drilldowncurrentchart="drilldowncurrentchartforbar" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <chartdrill v-if="drillpopup" :drilldata="drilldata" :templateId="templateId" :fromPage="'adverseEventsPop'"
    @closepopup="onclosed()" />
</template>
<script src="./DemographicSummaryReport.js"></script>