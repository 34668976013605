<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>Comparative Analysis</span>
      <div v-if="!loader" class="d-flex align-items-center">
        <label class="mb-0 ml-2 mr-1" for="x-axis-select">Attribute:</label>
        <select
          class="graph-select py-0"
          v-model="selectedXAxis"
          id="x-axis-select"
          @change="updateChart"
        >
          <option
            v-for="option in xAxisOptions"
            :key="option"
            :value="option"
          >{{ option }}</option>
        </select>

        <label
          v-if="fromPage === 'descriptiveStatistics' || fromPage === 'patientScreening'"
          class="mb-0 ml-2 mr-1"
          for="category-select"
        >Category:</label>
        <select
          class="graph-select py-0"
          v-model="selectedCategory"
          id="category-select"
          @change="updateChart"
        >
          <option
            v-for="option in categoryOptions"
            :key="option"
            :value="option"
          >{{ option }}</option>
        </select>
      </div>
      <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
        <img src="../../assets//img/expand.png" alt="Expand block">
      </div>
      <!-- <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer">
        <img src="../../assets//img/more.png" alt="More block">
      </div> -->
    </div>
    <div class="stat-body-white bar-chart-wrapper d-flex flex-column">
      <div v-if="enrolledornot && fromPage === 'patientScreening' && !loader">
        <label class="mr-2">
          <input
            type="checkbox"
            v-model="aiPredictionTrue"
            @change="updateChart"
          />
          Enrolled
        </label>
        <label>
          <input
            type="checkbox"
            v-model="aiPredictionFalse"
            @change="updateChart"
          />
          Non-enrolled
        </label>
      </div>
      <div v-if="loader" class="loader"></div>
      <svg class="w-100" ref="chartContainer" preserveAspectRatio="xMidYMid meet" v-show="!loader"></svg>
      <div ref="tooltip" class="tooltip"></div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false">
    <div class="outerdiv height-400 w-100">
    <svg ref="popupChartContainer" viewBox="0 0 1200 330" preserveAspectRatio="xMidYMid meet" v-show="!loader"></svg>
  </div>
  </enlargerpopup>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"
export default {
  name: "GroupedBarChart",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
    enrolledornot: {
      type: Boolean,
      default: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    enlargerpopup
  } ,
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      svg: null,
      selectedXAxis: "",
      selectedCategory: "",
      xAxisOptions: [],
      categoryOptions: [],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
      expandBlock:false,
      loading: true,
      tooltip:null,
      customColors: ['#62B2FD', '#9BDFC4', '#ABF0FF', '#C1D1FF', '#FBD164', '#FDAD73'],
      mouseX:0,
      mouseY:0,
      chartContainer:null,
    };
  },
  watch: {
    data: {
      handler() {
        this.loadAttributes();
        this.updateChart();
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    }
  },
  async beforeMount() {
    this.idtoken = store.getters.getIdToken;
    if (this.templateId) {
      await this.createMapping();
    }
    this.loadAttributes();
  },
  mounted() {
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    window.addEventListener('mousemove', this.updateMousePosition)
    this.tooltip=this.$refs.tooltip
    this.createChart();

    this.resizeObserver = new ResizeObserver(() => this.updateChart());
    this.resizeObserver.observe(this.chartContainer);
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    updateMousePosition(event) {
      if (this.$refs.component) {
      const rect = this.$refs.component.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    }
    },
    async createMapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach((page) => {
            page.field.forEach((field) => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce(
                  (map, option) => {
                    map[option.codevalue] = option.optionname;
                    return map;
                  },
                  {}
                );
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    loadAttributes() {
      const sampleData =
        this.data.length > 0 && this.data[0].features
          ? this.data[0].features
          : {};

      const qualitativeAttributes = [];
      const categories = [];

      Object.keys(sampleData).forEach((key) => {
        const uniqueValues = new Set(
          this.data.map((item) => item.features[key])
        ).size;
        if (uniqueValues < 6) {
          qualitativeAttributes.push(key);
          categories.push(key);
        }
      });

      this.xAxisOptions = qualitativeAttributes;
      this.selectedXAxis = qualitativeAttributes[0] || "";

      this.categoryOptions = categories;
      this.selectedCategory = categories[1] || "";

      this.updateChart();
    },
    async updateChart() {
      this.loading = true;
      await this.$nextTick();
      this.createChart();
      this.loading = false;
    },
    createChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
      const margin = { top: 70, right: 30, bottom: 40, left: 60 };
      const containerWidth = this.chartContainer.clientWidth;
      const containerHeight = this.chartContainer.clientHeight;
      const width = containerWidth - margin.left - margin.right;
      const height = containerHeight;

      const aggregatedData = this.aggregateData(this.data);
      console.log("height",containerHeight)
      if (aggregatedData.length === 0) {
        console.error("Aggregated data is empty");
        return;
      }

      const subCategories = Array.from(
        new Set(
          aggregatedData.flatMap((d) =>
            d.values.map((v) => v.category).filter((v) => v != null)
          )
        )
      );

      const colorScale = d3
        .scaleOrdinal()
        .domain(subCategories)
        .range([...this.customColors, ...d3.schemeCategory10]);

      this.xScale0 = d3
        .scaleBand()
        .domain(aggregatedData.map((d) => d.key))
        .range([0, width])
        .padding(0.1);

      this.xScale1 = d3
        .scaleBand()
        .domain(subCategories)
        .range([0, this.xScale0.bandwidth()])
        .padding(0.05);

      const maxYValue =
        d3.max(aggregatedData, (d) =>
          d3.max(d.values, (v) => v.value)
        ) || 0;
      const yAxisLimit = maxYValue + maxYValue * 0.1;

      this.yScale = d3
        .scaleLinear()
        .domain([0, yAxisLimit])
        .nice()
        .range([height, 0]);

      d3.select(this.chartContainer).selectAll("*").remove();

      this.svg = d3
        .select(this.chartContainer)
        .attr("viewBox", `0 100 ${containerWidth} 330`)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const bars = this.svg
        .append("g")
        .attr("class", "bars")
        .selectAll("g")
        .data(aggregatedData)
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${this.xScale0(d.key)},0)`);

      bars
        .selectAll("rect")
        .data((d) => d.values.filter((v) => v.value != null && !isNaN(v.value)))
        .enter()
        .append("rect")
        .attr("x", (d) => this.xScale1(d.category))
        .attr("y", (d) => this.yScale(d.value))
        .attr("height", (d) => this.yScale(0) - this.yScale(d.value))
        .attr("width", `40px`)
        .attr("fill", (d, i) => colorScale(d.category))
        .each(function (d) {
          this._currentColor = colorScale(d.category);
        })
        .on("mouseover", (event, d) => {
        d3.select(this.tooltip).style("opacity", 1)
      .html(`Category: ${d.category === "null" ? "Null" : d.category}<br/>Value: ${d.value}`)
      .style("left", this.mouseX + "px")
      .style("top", this.mouseY + "px")
       })
      .on("mouseout", () => {
        d3.select(this.tooltip).style("opacity", 0);
      })
      .on('mousemove', event => {
          d3.select(this.tooltip)
          .style("left",  this.mouseX + "px")
          .style("top", this.mouseY + "px");
        })
      bars
        .selectAll("text")
        .data((d) => d.values.filter((v) => v.value != null && !isNaN(v.value)))
        .enter()
        .append("text")
        .attr(
          "x",
          (d) => this.xScale1(d.category) + this.xScale1.bandwidth() / 2
        )
        .attr("y", (d) => this.yScale(d.value) + 10)
        .attr("text-anchor", "middle")
        .attr("fill", "black")
        .attr("transform", function (d) {
          return `rotate(-90, ${this.getAttribute(
            "x"
          )}, ${this.getAttribute("y")})`;
        })
        .text((d) => d.value);

      this.svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(this.xScale0))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

      this.svg.append("g").attr("class", "y-axis").call(d3.axisLeft(this.yScale));
    },
    validateData(data) {
      return data.filter((d) => {
        const aiPrediction = d.features.AIPrediction;
        if (
          (aiPrediction && this.aiPredictionTrue) ||
          (!aiPrediction && this.aiPredictionFalse)
        ) {
          return (
            d.features[this.selectedXAxis] !== undefined &&
            d.features[this.selectedCategory] !== undefined
          );
        }
        return false;
      });
    },
    aggregateData(data) {
      const validData = this.validateData(data);
      const nestedData = d3.group(
        validData,
        (d) =>
          this.getMappedValue(
            d.features[this.selectedXAxis],
            this.selectedXAxis
          ),
        (d) => d.features[this.selectedCategory]
      );

      return Array.from(nestedData, ([key, values]) => ({
        key,
        values: Array.from(values, ([category, records]) => ({
          category: this.getMappedValue(category, this.selectedCategory),
          value: records.length,
        })),
      }));
    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    },
  },
};
</script>

<style scoped>
@import "../AiCharts/charts.css";
.bar-chart-wrapper {
  overflow-x: auto;
  width: 100%;
}

.bar-chart {
  position: relative;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.legend-color.enrolled {
  background-color: darkblue;
}

.legend-color.non-enrolled {
  background-color: lightblue;
}

.controls {
  margin-bottom: 10px;
}

.controls label {
  margin-right: 5px;
}

.controls select {
  margin-right: 15px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.height-400{
  height:400px;
}
</style>