/* eslint-disable */
import axios from "axios";
export default {
  name: "AnalyticalDashboard",
  data(){
    return{
      refreshFlags: {
        DataAnalytics : true,
        OperationalAnalytics: false
      }
    }
  },
  methods:{
    handleTabClick(tab) {
      this.refreshFlags = [false, false];
      this.refreshFlags[tab] = true;
    },
  }
}
