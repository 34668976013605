<template>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-6">
        <h2>Subject Status Report</h2>
      </div>
      <div class="col-lg-2">
        <i class="fa fa-times close my-2" @click="pageclosed()"></i>
    </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-md-12 pl-1 mb-2">
                <historytab
                :historyarray="historykeys"
                @gotohistorylevel="gotohistorylevel"
                />
            </div>
            <div class="col-12 col-md-12 mb-2 pl-1">
                <barchart
                    :availableColumns="subjectKeys"
                    :loader="loader"
                    :data="data"
                    :templateId="''"
                    xAxisKey="DSDECOD"
                     :tableData="data"
                    yAxisKey="count"
                    chartlabel="Study of Medication Categories and Frequency"
                    :fromPage="'statusReport'"
                    @drilldowncurrentchart="drilldowncurrentchartforbar"
                    @opendrillpopup="drillpopupopened"
                    />
            </div>
            <div class="col-12 col-md-6 mb-2  pl-1">
                <stackedbarchart
                xAxisKey="EMPSTAT"
                yAxisKey=""
                :availableColumns="subjectKeys"
                segmentKey="EMPSTAT"
                :loader="loader"
                :data="data"
                :templateId="''"
                :fromPage="'statusReport'"
                chartlabel="Distribution of Subjects by Employment Status (EMPSTAT)"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"             
                />
            </div>
            <div class="col-md-6 mb-2  pl-1">
                <piechart
                :loader="loader"
                :data="data"
                :tableData="data"
                :templateId="''"
                :fromPage="'statusReport'"
                :displayValue="false"
                chartlabel="Distribution of Marital Status"
                :xaxisvariables="subjectKeys"
                :xaxiskey="xaxiskeys['piechart']"
                 @setxaxiskey="retainxaxiskey"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
            />
            </div>
           
            <div class="col-lg-12 mb-1 pl-0">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                    <span>Subject Status</span>
                    </div>
                    <div class="stat-body-white">
                    <advtable 
                        :loader="loader"
                        :data="data"
                        fromPage="patientRetention"
                        @drilldowncurrentchart="drilldowncurrentchartforbar"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <chartdrill
      v-if="drillpopup"
      :drilldata="drilldata"
      :templateId="''"
      :fromPage="'adverseEventsPop'"
      @closepopup="onclosed()"
    />
</template>
<script src="./status_report.js"></script>
<style scoped>

</style>