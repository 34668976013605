<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.submitted forms") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 pl-0 pr-1">
            <label class="mb-0">{{ $t("navigations.site code") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.SiteCode"
              name="account"
              @change="getpatients()"
            >
              <option value="All">{{ $t("navigations.all") }}</option>
              <option
                v-for="site in sites"
                :key="site.sitetId"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.subject id") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.PatientId"
              name="account"
            >
              <option value="All">{{ $t("navigations.all") }}</option>
              <option
                v-for="patient in patients"
                :key="patient.patientId"
                :value="patient.patientId"
              >
                {{ patient.subjectId }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.visit name") }}</label>
            <input
              type="search"
              v-model="searchdata.FormName"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.status") }}</label>
            <select
                class="form-select my-md-2 my-lg-0"
                v-model="searchdata.FormStatus"
                name="account"
            >
                <option value="All">{{ $t("navigations.all") }}</option>
                <option value="New">{{ $t("navigations.new") }}</option>
                <option value="Unverified">{{ $t("navigations.unverified") }}</option>
                <option value="Verified">{{ $t("navigations.verified") }}</option>
            </select>
          </div>
          <!-- <span type="button" @click.prevent="filteredLists" -->
          <span
            type="button"
            class="w-100 save_btn mx-lg-1 align-self-end"
            @click="SearchButtonClicked()"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">{{ $t("navigations.submitted forms") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.site code") }}</th>
                <th>{{ $t("navigations.subject id") }}</th>
                <th
                  class="sort_block"
                  @click="SortSelected('visitName', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit name") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('FormStatus', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('createdUserName', 5)"
                  id="5"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.uploaded by") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('createdAt', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.uploaded date") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('dataSource', 7)"
                  id="7"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.data source") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="text-center"  v-if="roleprivileges.includes('Forms View Form')">{{ $t("navigations.view") }}</th>
                <th class="text-center"  v-if="roleprivileges.includes('Forms Archive')">{{ $t("navigations.archive") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataList" :key="item.formId">
                <td>{{ item.siteId }}</td>
                <td>{{ item.subjectId }}</td>
                <td>{{ item.visitName }}</td>
                <td>{{ item.formStatus }}</td>
                <td>{{ item.createdUserName }}</td>
                <td>{{ filter(item.createdAt) }}</td>
                <td>{{ item.dataSource }}</td>
                <td class="text-center" v-if="roleprivileges.includes('Forms View Form')">
                  <i
                    class="fa fa-eye editicon"
                    @click.prevent="viewforms(item.formId)"
                  ></i>
                </td>
                <td class="text-center" v-if="roleprivileges.includes('Forms Archive')">
                  <i class="fa fa-archive editicon" aria-hidden="true" @click="archive(item.formId,item.subId)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
        <div class="row mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              "
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPageNumber"
                  :pages="totalLists"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script src="./form_listComponent.js" type="text/javascript">
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
</style>