<!--eslint-disable @typescript-eslint/explicit-module-boundary-types-->
<template>
  <div class="form-background">
    <div
      class="middle-box middle-height text-center loginscreen animated fadeInDown height-100 d-flex flex-column justify-content-center">
      <div class="login-image pb-1">
        <img src="../../assets/img/IDS_login.png" alt="">
      </div>
      <div class="tab-section mb-0">
        <!-- <div class="col-6 mb-0 pr-0"> -->
        <div class="mb-0 loginheader">
          <button class="login-tab-panels w-100 mb-0">Login</button>
        </div>
      </div>
      <div class="login-contents px-2 py-4">
        <div class="login">
          <form role="form" autocomplete="on">
            <div class="form-group">
              <input type="email" class="form-control" v-model="userid" placeholder="Username" required />
            </div>
            <div class="form-group">
              <input type="email" class="form-control" v-model="username" placeholder="Email" required />
            </div>
            <div class="form-group position-relative">
              <input :type="inputType" class="form-control mb-0" v-model="password" placeholder="Password" required />
              <i class="password-visible-block cursor-pointer position-absolute"
                :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']" @click.prevent="passwordVisible()"></i>
              <a class="float-right" href="CheckEmail">Forgot password/username?</a>
            </div>
            <div v-if="errorBlock" >
              <div class="errmsg">{{ errr }}</div>
            </div>
            <button type="submit" class="btn-primary btn-block m-b btn-colour" @click.prevent="login">
              <span v-if="loadingBlock && errr == ''"
                  ><img
                    src="../../assets/img/loading.webp"
                    width="20"
                    class="loading-img"
                    alt="Waiting for login"
                /></span>
                <span v-else>Login</span>
            </button>
          </form>
        </div>
        <div class="login-patient">
          <form>
            <div class="form-group">
              <input type="email" class="form-control" v-model="patientemail" placeholder="Username" required="" />
            </div>
            <div class="form-group">
              <input type="password" class="form-control mb-0" v-model="patientpassword" placeholder="Password"
                required="" />
              <a href="forgot_password.html" class="float-right">Forgot password?</a>
            </div>
            <button type="submit" class="btn-primary btn-block m-b btn-colour" @click.prevent="patientloginClicked">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./loginComponent.js"></script>

<style>
@import "../../assets/css/style.css";

@media (max-width: 768px) {
  .loginscreen.middle-box {
    width: clamp(200px, calc(100vw - 100px), 430px);
  }
}

/* .login-tab-panels,
.patient-tab-panels{
  border-bottom-width:0px;
} */
.middle-height {
  top: 5%;
  position: absolute;
}
.login-tab-panels {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: auto !important;
}

.patient-tab-panels {
  border-top-right-radius: 0.25rem;
}

.login-contents {
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.login-image>* {
  width: 100%;
}

.login-contents {
  background-color: #ffffff;
}

.height-100 {
  height: 100%;
}

.textcolor {
  color: #0d6efd;
}

.errmsg {
  font-size: 14px;
  color: red;
  float: left;
}

.social-icons {
  width: 16px;
  height: 16px;
}


.social-icons {
  width: 16px;
  height: 16px;
}

img {
  vertical-align: middle;
  border-style: none;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-colour {
  background-color: #145faf !important;
  border-color: #145faf !important;
  transition: background-color ease-in-out 500ms;
}

.btn-colour:hover {
  background-color: #2d91fb !important;
  border-color: #2d91fb !important;
}

.btn-white {
  background-color: white !important;
  margin-bottom: 10px !important;
}

.form-group {
  margin-bottom: 1rem;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
}

.form-background {
  margin-left: -15px;
}

.loginheader {
  font-size: 17px;
}
.password-visible-block {
  right: 11px;
  top: 11px
}
.loading-img {
  mix-blend-mode: lighten;
}
</style>
