import axios from "axios";
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import Percentagechart from "../../custom_lib/AiCharts/percentagechart.vue";
import D3PieChart from "../../custom_lib/AiCharts/D3PieChart.vue";
import stackedbarchart from '../../custom_lib/AiCharts/StackedBarchart.vue';
import histogram from '../../custom_lib/AiCharts/histogram.vue';
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import groupbarchart from '../../custom_lib/AiCharts/groupbarchart.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
export default{
  name: "demographicReport",
  props: {

  },
  components:{
    historytab,
    Percentagechart,
    D3PieChart,
    stackedbarchart,
    histogram,
    advtable,
    groupbarchart,
    chartdrill
  },
  data() {
     return{
        data :[],
        baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/report/",
        totalCount: 0,
        datahistory:[],
        historykeys:[],
        historydata:{},
        drilldata:{},
        drilldowndata:[],
        drillpopup:false,
        arrayofkeys: [
          "USUBJID", "BRTHDTC", "AGE", "AGEU", "SEX", "RACE", "RACEOTH", "COUNTRY", "MARITAL", "SMOKE", "ALCOHOL", "BMI", "COMORB", "DIET", "EXERFREQ", "HLTHSTAT", "MEDIC"
        ],
        xaxiskeys:{"groupbarchart":"",
          "barchart":"",
          "histogram":"",
          "piechart":"AGE",
          "advtable":"",
          "aepiechart":"",
          "historytab":"",
          "chartdrill":""},
        groupchartkeys:{
          "xaxiskey":"SEX",
        "groupkey":"ALCOHOL",
        },
        loader:true,
     };
  },
  async mounted(){
   
    await this.getdata();
    console.log("hi", this.totalCount)
  },
  methods: {
    async retaingroupchartvars(groupvars){
      if(groupvars.xaxiskey!="" && groupvars.groupkey!=""){
      this.groupchartkeys = groupvars;
      }
    },
    async getdata() {
        this.loader= true;
        await axios.post(
          `${this.baseUrl}getfilteredreports?table_name=Demographics`)
          .then((res) => {
            this.data = res.data;
            this.totalCount = res.data.length;
            this.loader= false;
            this.datahistory.push(this.data);
            this.historydata['Home'] = this.data;
            this.historykeys.push('Home');
          })
          .catch((err) => console.log("error in screen list", err));
      },
    async drillpopupopened(datafromchild) {
        this.drilldata = datafromchild;
        this.drillpopup = true;  
    },
    async drilldowncurrentchart(datafromchild){
      this.data = datafromchild;
      this.datahistory.push(this.data);
    },
    async drilldowncurrentchartforbar(datafromchild){
      console.log("sorteddataforbarchart",datafromchild);
      const lastkey = this.historykeys[this.historykeys.length - 1];
      // this.data = datafromchild.sortedData;
      if(this.historydata[lastkey].length != datafromchild.sortedData.length){
      // if(!this.historykeys.includes(datafromchild.filterkeyword)){
       this.historykeys.push(datafromchild.filterkeyword);
      this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
      this.data = this.historydata[datafromchild.filterkeyword];
      }
    },
    async gotohistorylevel(history){
      this.deleteAfterKeyvaluearrays(history);
      this.deleteAfterKeyarrays(history);
      this.data = this.historydata[history];
    },
    async deleteAfterKeyvaluearrays(selectedKey) {
      const entries = Object.entries(this.historydata); // Convert the object to an array of entries
      const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
    
      // If the key is found, slice the array to keep only the entries up to the selected key
      const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
    
      // Convert the array of entries back into an object
      return Object.fromEntries(newEntries);
    },
    async deleteAfterKeyarrays(selectedKey) {

      let index = this.historykeys.indexOf(selectedKey);

if (index !== -1) {
    // Slice the array up to the element (including the element)
    this.historykeys = this.historykeys.slice(0, index + 1);
}
    },
    async retainxaxiskey(xaxiskey){
      if(xaxiskey!=""){
      this.xaxiskeys["piechart"] = xaxiskey;
      }
    },
      async onclosed(){
        this.drillpopup = false;
      },
      async pageclosed(){
        this.$router.push(`/analyticalDashboard`);
      }
  },
};