<template>
  <!-- modal starts -->
  <div class="chart_modal__wrapper">
    <div class="chart__list__modal  p-0">
      <div class="chart__list__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">Detailed analysis</h4>
        <div class="cursor-pointer">
          <i class="fa fa-times" @click.prevent="closepopup()"></i>
        </div>
      </div>
      <div class="row" v-if="fromPage!='adverseEventsPop'">
          <div class="col-12 col-md-1">
            <div class="filter-bg d-flex align-items-center justify-content-center h-100">
              <img src="../../../assets/img/filter_icon.png" alt="filter">
            </div>
            
          </div>
          <div v-if="fromPage=='descriptiveStatistics' || fromPage=='adverseEvents'" class="col-12 col-md-4 my-2">
            <select
              class="graph-select"
              v-model="selectedfilterfield"
              id="attribute-select"
            >
            <option value="" disabled selected>Select a field</option>
              <option
                v-for="eachKey in Object.keys(drilldata[0])" :key="eachKey"
                :value="eachKey"
                >{{ eachKey }}</option
              >
            </select>
          </div>
          <div v-else class="col-12 col-md-4 my-2">
            <select
              class="graph-select"
              v-model="selectedfilterfield"
              id="attribute-select"
            >
            <option value="" disabled selected>Select a field</option>
              <option
                v-for="eachKey in Object.keys(drilldata[0].features)" :key="eachKey"
                :value="eachKey"
                >{{ eachKey }}</option
              >
            </select>
          </div>
          <div class="col-12 col-md-4 my-2">
            <div v-if="!isDropdown(this.selectedfilterfield)" class="d-flex justify-content-around">
              <input @input.prevent v-if="rangeFilters[this.selectedfilterfield]" type="number" v-model="rangeFilters[this.selectedfilterfield].min" class="form-control col-md-5" placeholder="Min">
              <input @input.prevent v-if="rangeFilters[this.selectedfilterfield]" type="number" v-model="rangeFilters[this.selectedfilterfield].max" class="form-control col-md-5" placeholder="Max">
            </div>
            <div v-else>
              <select  class="graph-select" v-model="filters[this.selectedfilterfield]" >
                <option value="" disabled selected>Select a value</option>
                <option value="">All</option>
                <option v-for="option in getUniqueValues(this.selectedfilterfield)" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-3 my-2 pl-0 pr-4">
            <!-- <select  class="graph-select" v-if="fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'">
              <option value="" disabled selected>Select a column</option>
                <option
                  v-for="eachKey in Object.keys(drilldata[0])" :key="eachKey"
                  :value="eachKey"
                  >{{ eachKey }}</option
                >
            </select> -->
            <button type="button" class="btn btn-primary btn-sm py-0" @click="applyFilters">Search</button>
            <!-- <select v-else  class="graph-select">
              <option value="" disabled selected>Select a column</option>
                <option
                  v-for="eachKey in Object.keys(drilldata[0].features)" :key="eachKey"
                  :value="eachKey"
                  >{{ eachKey }}</option
                >
            </select> -->
          </div>
      </div>
      <div class="ibox-content" v-if="fromPage!='adverseEventsPop'">
        <div class="tab-content">
         <div class="list-group scrollable"   >
            <table v-if="drilldata.length != 0 && (fromPage=='descriptiveStatistics')" class="table table-striped table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th v-for="eachKey in Object.keys(drilldata[0])" :key="eachKey" style="min-width: 100px;" class="text-dark">
                    {{ eachKey }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="eachobj in filteredData" :key="eachobj">
                  <td v-for="(eachval, key) in eachobj" :key="key" style="min-width: 100px;">
                    {{ getMappedValue(eachval, key) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-if="drilldata.length != 0 && fromPage!='descriptiveStatistics' && fromPage!='adverseEventsPop'" class="table table-striped table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th v-for="eachKey in Object.keys(drilldata[0].features)" :key="eachKey" style="min-width: 100px;" class="text-dark">
                    {{ eachKey }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="eachobj in filteredData" :key="eachobj">
                  <td v-for="(eachval, key) in eachobj.features" :key="key" style="min-width: 100px;">
                    {{ getMappedValue(eachval, key) }}
                  </td>
                </tr>
              </tbody>
            </table> 
          </div>
        </div>
      </div>
          <advtable 
              v-if="fromPage=='adverseEventsPop'"
              :fromPage="fromPage"
              :loader="loader"
              :data="drilldata"
              @drilldowncurrentchart="drilldowncurrentchartforbar"/>
        </div>
     
      </div>
</template>
 
<script>
/* eslint-disable */
import axios from "axios";
import moment from "moment";
import store from "../../../store/index";
import advtable from '../advenenttable.vue';
import * as d3 from "d3";

export default {
  name: "listApprover",
  components:{
    advtable
  },
  props: {
    drilldata: {
      type: Object,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: "",
    },
    fromPage:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      selectedfilterfield:"",
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      displaySidePopup: false,
      mappings: {},
      filters: {},
      rangeFilters: {}, // Added rangeFilters data property
      activeTab: 'table',
    };
  },
  async beforeMount(){
    this.initializeFilters();
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;
    document.body.style.overflowY="hidden";
    if(this.fromPage !='adverseEventsPop' )
    {
      if(this.fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'  )
      {
        this.initializeFilters();
        this.renderD3BarChart();
      }
      else{
        await this.createmapping();
        this.initializeFilters();
        this.renderD3BarChart();
      }
    }
  },
  unmounted() {
    document.body.style.overflowY="auto";
  },
  methods: {
    async closepopup(){
      this.$emit('closepopup');
    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach((page) => {
            page.field.forEach((field) => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce(
                  (map, option) => {
                    map[option.codevalue] = option.optionname;
                    return map;
                  },
                  {}
                );
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    initializeFilters() {
      if (this.drilldata.length > 0) {
        if(this.fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'  )
          {
            const keys = Object.keys(this.drilldata[0]);
            keys.forEach(key => {
              this.filters[key] = '';
              this.rangeFilters[key] = { min: '', max: '' }; // Initialize range filters
            });
          }
          else{
            const keys = Object.keys(this.drilldata[0].features);
            keys.forEach(key => {
              this.filters[key] = '';
              this.rangeFilters[key] = { min: '', max: '' }; // Initialize range filters
            });
          }

      }
  },
    getMappedValue(value, key) {
      if (this.mappings[key] && this.mappings[key][value]) {
        return this.mappings[key][value];
      } else {
        return value;
      }
    },
    isDropdown(key) {
      if(this.fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'  )
        {
          const value = this.drilldata[0][key];
          return (this.mappings[key] && Object.keys(this.mappings[key]).length > 0) || isNaN(value) || typeof value === 'boolean';
        }
      else{
        const value = this.drilldata[0].features[key];
        return (this.mappings[key] && Object.keys(this.mappings[key]).length > 0) || isNaN(value) || typeof value === 'boolean';
      }
    },
    getUniqueValues(key) {
      if(this.fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'  )
        {
          const uniqueValues = new Set(this.drilldata.map(item => this.getMappedValue(item[key], key)));
          return [...uniqueValues];
        }
        else{
          const uniqueValues = new Set(this.drilldata.map(item => this.getMappedValue(item.features[key], key)));
          return [...uniqueValues];
        }
     
    },
    applyFilters() {
      this.filteredData = this.drilldata.filter(item => {
        return Object.keys(this.filters).every(key => {
          const filterValue = this.filters[key].toString();
          if(this.fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'  )
          {
            const itemValue = this.getMappedValue(item[key], key) ? this.getMappedValue(item[key], key).toString() : '';
              if (!this.isDropdown(key)) {
              const min = this.rangeFilters[key] ? this.rangeFilters[key].min : '';
              const max = this.rangeFilters[key] ? this.rangeFilters[key].max : '';
              const numValue = parseFloat(itemValue);
              return (!min || numValue >= parseFloat(min)) && (!max || numValue <= parseFloat(max));
            }
            return itemValue.includes(filterValue);
          }
          else{
            const itemValue = this.getMappedValue(item.features[key], key).toString();
              if (!this.isDropdown(key)) {
              const min = this.rangeFilters[key] ? this.rangeFilters[key].min : '';
              const max = this.rangeFilters[key] ? this.rangeFilters[key].max : '';
              const numValue = parseFloat(itemValue);
              return (!min || numValue >= parseFloat(min)) && (!max || numValue <= parseFloat(max));
            }
            return itemValue.includes(filterValue);
          }          
        });
      });
    },
    renderD3BarChart() {
      this.$nextTick(() => {
        if(this.fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'  ){
          const data = this.drilldata.map(item => ({
          label: this.getMappedValue(item['labelKey'], 'labelKey'), // Change 'labelKey' to the appropriate key for your labels
          value: item['valueKey'] // Change 'valueKey' to the appropriate key for your data values
        }));
        const margin = { top: 20, right: 30, bottom: 40, left: 40 };
        const width = 800 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
 
        const svg = d3.select("#d3BarChart")
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);
 
        const x = d3.scaleBand()
          .domain(data.map(d => d.label))
          .range([0, width])
          .padding(0.1);
 
        svg.append("g")
          .attr("transform", `translate(0,${height})`)
          .call(d3.axisBottom(x));
 
        const y = d3.scaleLinear()
          .domain([0, d3.max(data, d => d.value)])
          .nice()
          .range([height, 0]);
 
        svg.append("g")
          .call(d3.axisLeft(y));
 
        svg.selectAll(".bar")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("x", d => x(d.label))
          .attr("y", d => y(d.value))
          .attr("width", x.bandwidth())
          .attr("height", d => height - y(d.value))
          .attr("fill", "steelblue");
        }
        else{
          const data = this.drilldata.map(item => ({
          label: this.getMappedValue(item.features['labelKey'], 'labelKey'), // Change 'labelKey' to the appropriate key for your labels
          value: item.features['valueKey'] // Change 'valueKey' to the appropriate key for your data values
        }));
        const margin = { top: 20, right: 30, bottom: 40, left: 40 };
        const width = 800 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
 
        const svg = d3.select("#d3BarChart")
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);
 
        const x = d3.scaleBand()
          .domain(data.map(d => d.label))
          .range([0, width])
          .padding(0.1);
 
        svg.append("g")
          .attr("transform", `translate(0,${height})`)
          .call(d3.axisBottom(x));
 
        const y = d3.scaleLinear()
          .domain([0, d3.max(data, d => d.value)])
          .nice()
          .range([height, 0]);
 
        svg.append("g")
          .call(d3.axisLeft(y));
 
        svg.selectAll(".bar")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("x", d => x(d.label))
          .attr("y", d => y(d.value))
          .attr("width", x.bandwidth())
          .attr("height", d => height - y(d.value))
          .attr("fill", "steelblue");
        }
      });
    }
  },
  computed: {
    filteredData() {
      return this.drilldata.filter(item => {
        return Object.keys(this.filters).every(key => {
          if(key!=null){
            const filterValue = this.filters[key].toString();
          if(this.fromPage=='descriptiveStatistics' || this.fromPage=='adverseEvents'  ){
            const itemValue = this.getMappedValue(item[key], key) ? this.getMappedValue(item[key], key).toString() : '';
            if (!this.isDropdown(key)) {
              const min = this.rangeFilters[key] ? this.rangeFilters[key].min : '';
              const max = this.rangeFilters[key] ? this.rangeFilters[key].max : '';
              const numValue = parseFloat(itemValue);
              return (!min || numValue >= parseFloat(min)) && (!max || numValue <= parseFloat(max));
            }
            return itemValue.includes(filterValue);
          }
          else{
            const itemValue = this.getMappedValue(item.features[key], key).toString();
            if (!this.isDropdown(key)) {
              const min = this.rangeFilters[key] ? this.rangeFilters[key].min : '';
              const max = this.rangeFilters[key] ? this.rangeFilters[key].max : '';
              const numValue = parseFloat(itemValue);
              return (!min || numValue >= parseFloat(min)) && (!max || numValue <= parseFloat(max));
            }
            return itemValue.includes(filterValue);
          }
          }
          
          
        });
      });
    },
  },
};
</script>
 
<style scoped>
@import url("../charts.css");
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
 
.disabled .tool-tip {
  opacity: 0;
}
 
.position-relative .tool-tip--add-approver {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 24px;
  top: 3px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
 
.position-relative:hover .tool-tip--add-approver {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
 
.scrollable {
  max-height: 400px;
  overflow-y: auto;
}
 
.table th,
.table td {
  vertical-align: middle;
  text-align: center;
}
 
.thead-dark th {
  background-color: #343a40;
  color: white;
}
 
.totaltabs {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
 
.subtab {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-bottom: none;
  background-color: #f9f9f9;
}
 
.subtab.activesubtab {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}
 
.tab-content {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
}
 
.chart-container {
  width: 100%;
  height: 400px;
}
</style>