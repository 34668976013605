<template>
  <div class="stat-card-white">
    <div class="stat-header-white d-flex justify-content-between">
      <span>{{ chartLabel }}</span>
      <div v-show="!loader && fromPage==='labReport'" class="d-flex align-items-center">
        <!-- Dropdown for VISIT (or custom field) -->
        <select class="graph-select py-0" v-model="selectedVisit" @change="onVisitChange">
          <option v-for="visit in visits" :key="visit" :value="visit">
            {{ visit }}
          </option>
        </select>
        <div v-if="!loader && fromPage === 'labReport'" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>Data Overview</span>
          </div>
        </div>
        <div v-if="!loader && fromPage === 'labReport'" class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
      </div>
    </div>
    <div class="stat-body-white" :class="{'h-100': loader}">
      <div v-if="loader" class="loader"></div>
      <div ref="barChart" class="chart-container" v-show="!loader"></div>
      <!-- Tooltip for displaying the percentage on hover -->
      <div ref="tooltip" class="tooltip"></div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,createBarChart()">
    <div ref="popupChartContainer" class="chart-container"></div>
  </enlargerpopup>
</template>

<script>
import * as d3 from 'd3';
import  enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
export default {
  name: 'HorizontalBarChart',
  components:{
    enlargerpopup
  },  
  props: {
    data: Object,
    dataArray: {
      type: Array,
      default: () => [],
    },
    visitField: {
      type: String,
      default: 'VISIT' 
    },
    lbstnrindField: {
      type: String,
      default: 'LBSTNRIND' 
    },
    fromPage: {
      type: String,
      default: ''
    },
    xAxisKey: {
      type: String,
      default: 'TRTDUR',
    },
    visitselected: {
      type: String,
      default: '',
    },
    plotValue: {
      type: Array,
      default: () => [],
    },
    chartLabel: {
      type: String,
      default: '',
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      container: null,
      tooltip:null,
      selectedXAxis: "",
      selectedVisit: "", 
      visits: [],
      showMore:true,
      expandBlock:false,
      mouseX:0,
      mouseY:0
    };
  },
  watch: {
    data: {
      handler() {
        this.createBarChart();
      },
      immediate: true
    },
    dataArray: {
      handler() {
        this.extractVisitValues(); 
        this.$nextTick(() => {
          this.container = this.$refs.barChart;
          this.createBarChart();
        });
      },
      immediate: true
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createBarChart();
        });
      }
    }
  },
  async mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    this.tooltip=this.$refs.tooltip
    this.selectedVisit = this.visitselected;
    this.selectedXAxis = this.availableColumns[0];
    this.$nextTick(() => {
      this.container = this.$refs.barChart;
      this.tooltip=this.$refs.tooltip

      window.addEventListener('resize', this.createBarChart);
      this.createBarChart();
    });
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
    window.removeEventListener('resize', this.createBarChart);
  },
  methods: {
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      const rect = this.$refs.barChart.getBoundingClientRect();

      // Calculate the mouse X and Y positions relative to the component
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    showtable(){
      this.showMore=false
      this.$emit("opendrillpopup", this.dataArray);
    },
    drilldownfuntion(value){
      const dataToEmit = {
        sortedData: value.dataArray,
        filterkeyword: `Visit(${this.selectedVisit}),LBSTNRIND(${value.name})`
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
      console.log('Data array for clicked bar:', value);
    },
    extractVisitValues() {
      
      const uniqueVisits = [...new Set(this.dataArray.map(item => item[this.visitField]))];
      this.visits = uniqueVisits;
      if(this.selectedVisit == ""){
        if(this.visitselected == ""){
      this.selectedVisit = this.visits[0];
      this.$emit("setvisitkey", this.selectedVisit);
        }
        else{
          this.selectedVisit = this.visitselected;
        }
      } 
    },
    onVisitChange() {
      this.$emit("setvisitkey", this.selectedVisit);
      this.createBarChart(); 
    },
    createBarChart() {
      this.container = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.barChart;
      if (!this.container || this.container.clientWidth === 0) {
        setTimeout(() => {
          this.createBarChart();
        }, 100);
        return;
      }

  d3.select(this.container).selectAll("*").remove();

  let filteredData;

  if (this.fromPage === 'labReport') {
    if (!this.dataArray.length) {
      console.log('No data available for chart');
      return;
    }

    // Filter data based on the selected visit field and value
    filteredData = this.dataArray.filter(d => d[this.visitField] === this.selectedVisit);

    // Calculate the percentage for each unique value in the lbstnrindField
    const totalCount = filteredData.length;
    const lbstnrindCounts = d3.rollup(filteredData, v => v.length, d => d[this.lbstnrindField]);
    const plotData = Array.from(lbstnrindCounts, ([key, count]) => ({
      name: key == undefined ? 'NULL' : key,
      percentage: (count / totalCount) * 100, // Calculate percentage
      dataArray: filteredData.filter(d => d[this.lbstnrindField] === key)
      // Capture the specific data array for this bar
    }));
    // Set up chart dimensions and scales
    const margin = { top: 20, right: 20, bottom: 30, left: 200 };
    const width = this.container.clientWidth - margin.left - margin.right;
    const height = plotData.length * 40;

    const x = d3.scaleLinear().range([0, width]).domain([0, 100]);
    const y = d3.scaleBand().range([0, height]).padding(0.1).domain(plotData.map(d => d.name));

    const colors = d3.scaleOrdinal(d3.schemeCategory10);

    // Create the SVG container
    const svg = d3.select(this.container).append("svg")
      .attr("viewBox", `0 0 ${width + margin.left + margin.right + 100} ${height + margin.top + margin.bottom }`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);


    // Add x-axis
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(5).tickFormat(d => `${d}%`));

    // Add y-axis
    svg.append("g")
      .attr("class", "y axis")
      .call(d3.axisLeft(y));

    // Add bars
    svg.selectAll(".bar")
      .data(plotData)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", 0)
      .attr("y", d => y(d.name))
      .attr("width", d => x(d.percentage))
      .attr("height", y.bandwidth())
      .attr("fill", (d, i) => colors(i))
      .on("mouseover", (event, d) =>{
        d3.select(this.tooltip)
        .style("opacity", 1)
        .html(`Value: ${d.name}<br>Percentage: ${d.percentage.toFixed(2)}%`)
        .style("left", `${this.mouseX}px`)
        .style("top", `${this.mouseY}px`);
      })
      .on("mouseout", () => {
          d3.select(this.tooltip).style("opacity", 0);
        })
      .on('mousemove', event => {
        d3.select(this.tooltip)
        .style("left",  this.mouseX + "px")
        .style("top", this.mouseY + "px");
      })
      .on("click", (event, d) => {
        // Log the data array for the clicked bar
        d3.select(this.tooltip).style("opacity", 0);
        this.drilldownfuntion(d);
      });

    // Add labels for the bars
    svg.selectAll(".label")
      .data(plotData)
      .enter().append("text")
      .attr("class", "label")
      .attr("x", d => x(d.percentage) + 5)
      .attr("y", d => y(d.name) + y.bandwidth() / 2)
      .attr("dy", ".35em")
      .text(d => `${d.percentage.toFixed(2)}%`);
  }
  }
}}
</script>

<style>
@import "../../custom_lib/AiCharts/charts.css";
.chart-container {
  width: 100%;
  height: auto;
}

.bar {
  fill-opacity: 0.8;
}

.label {
  font-size: 12px;
  fill: black;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.minheight {
  height: 405px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
