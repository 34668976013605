<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-6 col-md-8">
      <h2>{{ $t("navigations.descriptive statistic report") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-12 col-md-4 pr-0 mb-1">
        <div class="stat-card">
          <div class="stat-header">
            <span>Total Participants</span>
          </div>
          <div class="stat-body">
            <div class="stat-info">
              <h1>{{ displayValue }}</h1>
              <small>Participants</small>
            </div>
            <div class="stat-icon">
              <img src="../../assets/img/stat_card_icon.png" alt="Icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 mb-1">
        <Percentagechart :data="data" :fromPage="'descriptiveStatistics'" />
      </div>
      <div class="col-md-12 mb-2">
        <historytab
          :historyarray="historykeys"
          @gotohistorylevel="gotohistorylevel"
        />
      </div>
      <div class="col-12 col-md-5 mb-1 pr-0">
        <D3PieChart
          :data="data"
          :tableData="data"
          :templateId="''"
          :fromPage="'descriptiveStatistics'"
          @opendrillpopup="drillpopupopened"
          @drilldowncurrentchart="drilldowncurrentchartforbar"
          @undobutton="undobutton"
        />
      </div>
      <div class="col-12 col-lg-7 mb-1">
        <groupbarchart :data="data"
         :fromPage="'descriptiveStatistics'"
          @opendrillpopup="drillpopupopened"
         @drilldowncurrentchart="drilldowncurrentchartforbar"
          />
      </div>
      <div class="col-12 mb-1">
        <sunburst :data="data" :fromPage="'descriptiveStatistics'" />
      </div>
      <div class="col-12 mb-1 ">
        <div
          class="stat-card-white "
          :class="{ 'sunburst-minheight': fromPage == 'patientScreening' }"
        >
          <div class="stat-header-white">
            <label class="mb-0 ml-2 mr-1" for="category-select"
              >Category:</label
            >
            <select
              class="graph-select"
              v-model="selectedCategory"
              id="category-select"
              @change="updateChart"
            >
              <option
                v-for="option in Object.keys(getSelectedData)"
                :key="option"
                :value="option"
                >{{ option }}</option
              >
            </select>
          </div>
          <div class="stat-body-white">
            <d3table
              :data="[]"
              :dataObject="getSelectedData"
              :enrolledornot="false"
              :selectedValue="selectedCategory"
              :fromPage="'adverseEventsPop'"
              :templateId="null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <chartdrill
      v-if="drillpopup"
      :drilldata="drilldata"
      :templateId="templateId"
      fromPage="adverseEventsPop"
      @closepopup="onclosed()"
    />
</template>
<script src="./descriptiveStatistic.js"></script>
<style>
@import "../../custom_lib/AiCharts/charts.css";
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.widgetmargin {
  margin-top: -13px !important;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}

.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}

.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}

.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}

.titlemsg {
  border-color: #e7eaec;
  margin-bottom: 0;
  padding: 20px 20px;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding-left: 10px;
}

.padding_bottom {
  padding-bottom: 2rem;
}

.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}

.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}

.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}

.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}

.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}

.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}

.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}

.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}

.graphbtncontainer {
  display: flex;
  gap: 5px;
}

img {
  margin-left: 0px !important;
}

.selectdefault {
  left: 0;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn-setdefault {
  position: relative;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-size: 0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius: 3px;
}

.ibox-content h1 {
  font-weight: 400;
  color: #676a6c;
}

.progress {
  display: flex;
  height: 5px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.progress-mini {
  flex-grow: 1;
  margin-right: 10px;
}

.progress-bar {
  background-color: #5bc0de;
  height: 20px;
}

.progress-value {
  color: #333;
  font-size: 1.2em;
}

.average-title {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #888;
}

.stat-item h3 {
  margin-bottom: 5px;
  font-size: 1em;
  color: #888;
}

.stat-item h2 {
  margin-bottom: 5px;
  font-size: 2em;
  color: #333;
}

.stat-item small {
  color: #888;
}

.stat-info h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info small {
  font-size: 1em;
}

.stat-icon img {
  width: 50px;
  height: 50px;
}

.stat-body-dflex {
  margin: 5px 15px !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 125px;
}

.stat-info-white h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info-white small {
  font-size: 1em;
}

.stat-dropdown {
  position: relative;
  display: inline-block;
}

.stat-dropbtn {
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.stat-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10001;
  right: 0;
}

.stat-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.stat-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.stat-dropdown:hover .stat-dropdown-content {
  display: block;
}

.stat-dropdown:hover .dropbtn {
  color: #000;
}

.adjustheight {
  height: 220px;
}

.minheight {
  height: 405px;
}
</style>
